export const rulesFormGettingSlots = {
  unload_id: [
    {
      required: true,
      message: 'Пожалуйста, выберите терминал',
      trigger: 'change',
    },
  ],
  requests_count: [
    {
      required: true,
      message: 'Пожалуйста, выберите количество грузовиков',
      trigger: 'change',
    },
  ],
  culture_id: [
    {
      required: true,
      message: 'Пожалуйста, выберите культуру',
      trigger: 'change',
    },
  ],
  truck_type_code: [
    {
      required: true,
      message: 'Пожалуйста, выберите тип авто',
      trigger: 'change',
    },
  ],
  supplier_id: [
    {
      required: true,
      message: 'Пожалуйста, выберите поставщика',
      trigger: 'change',
    },
  ],
  day: [
    {
      required: true,
      message: 'Пожалуйста, выберите дату ',
      trigger: 'change',
    },
  ],
  time: [
    {
      type: 'string',
      required: true,
      message: 'Пожалуйста, выберите время ',
      trigger: 'change',
    },
  ],
}
